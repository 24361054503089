
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */

:root {
  scroll-behavior: smooth;
}


body {
  background: #131212;
  margin: 0;
}